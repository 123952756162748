body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.App {
    background-color: #282c34;
    font-size: calc(20px + 2vh);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    font-family: "Helvetica Neue", monospace, sans-serif;
    text-transform: uppercase;
    margin: -1em auto 0 auto;
    font-size: 1em;
    color: #d90757;
    height: 1em;
    display: block;
}

.not-found {
    font-family: "Helvetica Neue", monospace, sans-serif;
    text-transform: uppercase;
    margin: 0 auto;
    font-size: 1em;
    color: #d90757;
    height: 1em;
    text-align: center;
    width: 120px;
    display: flex;
    flex-direction: column;
}

.home-link{
    font-size: 12px;
    color: azure;
    margin: 1em auto;
}

.logo-icon {
    display: inline-block;
    background: url("/logo.svg") no-repeat top left;
    background-size: contain;
    width: 0.9em;
    height: 0.9em;
}

.logo-name {
    padding-left: 0.2em;
    vertical-align: top;
    line-height: 1em;
    height: 0.9em;
    letter-spacing: 0.15em;;
}

.launch {
    font-size: 0.7em;
    color: ivory;
    display: none;
}